import * as React from 'react';
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import { DateTime } from 'luxon';
import {
    ApplicationUser,
    AuthContext,
    AuthContextState,
} from '../../auth/AuthContext';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {UserSwitchOutlined} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

export const Accounts = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [spin, setSpin] = useState(false);
    const [accounts, setAccounts] = useState<ApplicationUser[]>([]);

    const load = () => {
        sendGet(`accounts`)
            .then((res) => res.json())
            .then((d) => setAccounts(d));
    };

    useEffect(() => {
        load();
    }, []);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }
    ];

    return (
        <Fragment>
            <FullPageSpin visible={spin} />
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Accounts
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={'id'} dataSource={accounts} columns={columns} />
                </Col>
            </Row>
        </Fragment>
    );
};
