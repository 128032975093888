import * as React from 'react';
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import { DateTime } from 'luxon';
import {
    ApplicationUser,
    AuthContext,
    AuthContextState,
} from '../../auth/AuthContext';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {GithubOutlined, GoogleOutlined, UserSwitchOutlined, WindowsOutlined} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

export const Users = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [spin, setSpin] = useState(false);
    const [users, setUsers] = useState<ApplicationUser[]>([]);

    const load = () => {
        sendGet(`users`)
            .then((res) => res.json())
            .then((d) => setUsers(d));
    };


    useEffect(() => {
        load();
    }, []);

    const onImpersonate = async (user: ApplicationUser) => {
        const tenant = await sendGet('tenants/current').then((res) => res.json());

        await sendPostToAuth(`auth/impersonate`, {
            'userId': user.id
        }, tenant.host);
        window.location.href = tenant.applicationUrl + '/success-oauth-login';
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, row: any) => {
                return (<Paragraph copyable>{text}</Paragraph>)
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text: string, row: any) => {
                return (<Paragraph copyable>{text}</Paragraph>)
            }
        },
        {
            title: 'Created',
            key: 'created',
            render: (text: string, row: any) => {
                return (
                    <p key={'created' + row.id}>
                        {DateTime.fromISO(row.created).toFormat('d LLL yyyy, T')}
                    </p>
                );
            },
        },
        {
            title: 'Provider',
            dataIndex: 'loginProvider',
            key: 'loginProvider',
            render: (text: string, row: any) => {
                if (text === 'Google') {
                    return (<GoogleOutlined title={"Google"}/>);
                } else if (text === 'GitHub') {
                    return (<GithubOutlined title={"GitHub"}/>);
                } else if (text === 'Microsoft') {
                    return (<WindowsOutlined title={"Microsoft"}/>);
                } else {
                    return text;
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, row: any) => (
                <Space key={'action' + row.id} size="middle">
                    <Button
                        type="primary"
                        onClick={toggleVisibility(setSpin, onImpersonate, row)}
                        title={"Impersonate"}>
                        <UserSwitchOutlined/>Impersonate
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <Fragment>
            <FullPageSpin visible={spin}/>
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Users
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={'id'} dataSource={users} columns={columns}/>
                </Col>
            </Row>
        </Fragment>
    );
};