import * as React from 'react';
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import {Button, Col, Descriptions, Row, Space, Table, Typography} from 'antd';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';

export const Authentication = (): ReactElement => {
    const [spin, setSpin] = useState(false);
    const [settings, setSettings] = useState<any>([]);

    const load = () => {
        sendGet(`authentication/settings`)
            .then((res) => res.json())
            .then((d) => setSettings(d));
    };

    useEffect(() => {
        load();
    }, []);


    const hasSocial = (settings: any): boolean => {
        return settings?.googleClientId ||
        settings?.microsoftClientId ||
        settings?.gitHubClientId;
    }

    return (
        <Fragment>
            <FullPageSpin visible={spin} />
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Authentication
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Descriptions title="">
                        <Descriptions.Item label="Id">{settings?.id}</Descriptions.Item>
                        <Descriptions.Item label="Host">{settings?.host}</Descriptions.Item>
                        <Descriptions.Item label="Application Url">{settings?.applicationUrl}</Descriptions.Item>
                    </Descriptions>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel header="Jwt" key="1">
                            <Descriptions title="">
                                <Descriptions.Item label="Audience">{settings?.jwtAudience}</Descriptions.Item>
                                <Descriptions.Item label="Issuer">{settings?.jwtIssuer}</Descriptions.Item>
                            </Descriptions>
                        </Collapse.Panel>
                        <Collapse.Panel header="Coockie" key="2">
                            <Descriptions title="">
                                <Descriptions.Item label="Domain">{settings?.cookieDomain}</Descriptions.Item>
                                <Descriptions.Item label="Secure">{settings?.cookieSecure ? "true" : "false"}</Descriptions.Item>
                            </Descriptions>
                        </Collapse.Panel>
                        {hasSocial(settings) && (<Collapse.Panel header="Social" key="3">
                                <Descriptions title="">
                                    <Descriptions.Item label="Google Client Id">{settings?.googleClientId}</Descriptions.Item>
                                    <Descriptions.Item label="Microsoft Client Id">{settings?.microsoftClientId}</Descriptions.Item>
                                    <Descriptions.Item label="GitHub Client Id">{settings?.gitHubClientId}</Descriptions.Item>
                                </Descriptions>
                        </Collapse.Panel>)}
                    </Collapse>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Descriptions title="Google">
                        <Descriptions.Item label="GCE console"><a target={"_blank"} href={'https://console.cloud.google.com/projectselector2/apis/credentials/consent'}>link</a></Descriptions.Item>
                        <Descriptions.Item label="Authorized JavaScript origins">{settings?.applicationUrl}</Descriptions.Item>
                        <Descriptions.Item label="Authorized redirect URI">https://{settings?.host}/signin-google</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Fragment>
    );
};
