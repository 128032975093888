import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {
    TeamOutlined,
    LogoutOutlined,
    HomeOutlined,
    UserOutlined, IdcardOutlined, LockOutlined, ApiOutlined, RobotOutlined, ThunderboltOutlined
} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthContext, AuthContextState, isSuperAdmin} from "../auth/AuthContext";

const {Content, Footer, Sider} = Layout;

let rawMenuItems = [
    {
        path: '/',
        icon: <HomeOutlined/>,
        title: 'Tenant',
        id: '',
        order: 0
    },
    {path: '/users', icon: <UserOutlined/>, title: 'Users', id: '', order: 0},
    {path: '/accounts', icon: <TeamOutlined/>, title: 'Accounts', id: '', order: 0},
    {path: '/authentication', icon: <IdcardOutlined />, title: 'AuthN', id: '', order: 0},
    {path: '/authorization', icon: <LockOutlined />, title: 'AuthZ', id: '', order: 0},
    {path: '/integrations', icon: <ApiOutlined />, title: 'Integrations', id: '', order: 0},
    {path: '/events', icon: <ThunderboltOutlined />, title: 'Events', id: '', order: 0},
    {path: '/automations', icon: <RobotOutlined />, title: 'Automations', id: '', order: 0},
    {
        path: '/sign-out',
        icon: <LogoutOutlined/>,
        title: 'Sign Out',
        id: '',
        order: 0
    },
].map((obj, i) => {
    obj.id = (i + 1).toString();
    obj.order = (i + 1);
    return obj;
});

export const MainLayout = (props: any) => {
    const authContext = useContext<AuthContextState>(AuthContext);

    const navigator = useNavigate();
    // eslint-disable-next-line react/prop-types
    const {to, staticContext, children} = props;
    const [collapsed, setCollapsed] = useState(false);
    const [menuItems, setMenuItems] = useState(rawMenuItems);
    const [selectedLink, setSelectedLink] = useState('1');
    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        const item = menuItems.find((i) => i.id !== '1' && path.startsWith(i.path));

        setSelectedLink(item?.id ?? menuItems[0].id);
    }, [location, menuItems]);

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={[selectedLink]}
                    selectedKeys={[selectedLink]}
                    mode="inline"
                    onClick={(e) => {
                        const item = menuItems.at(parseInt(e.key) - 1);
                        if (item) {
                            navigator(item.path);
                        }
                    }}
                    items={menuItems.map((obj, i) => {
                        return {
                            key: obj.id,
                            icon: obj.icon,
                            label: obj.title
                        };
                    })}
                />
            </Sider>
            <Layout className="site-layout" style={{backgroundColor: '#fff'}}>
                <Content>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 360}}
                    >
                        {children}
                    </div>
                </Content>

                {/*<Chat enabled={true} propertyId={"64823f84cc26a871b0217253"} widgetId={"1h2ecg84k"}/>*/}
                <Footer style={{textAlign: 'center'}}>
                    &copy; 2023 |{' '}
                    <a target={'_blank'} href={'mailto:support@1saas.tools'} rel="noreferrer">Support</a>
                </Footer>
            </Layout>
        </Layout>
    );
};
