import { ApiRouter } from './apiRouteBuilder';

export class RouterBuilder {
  apiUrl: string;
  authApiUrl: string;
  api: ApiRouter;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL ?? "";
    this.authApiUrl = process.env.REACT_APP_AUTH_API_URL ?? "";
    this.api = new ApiRouter(this.apiUrl, this.authApiUrl);
  }

  home(): string {
    return '/';
  }

  login(): string {
    return '/login';
  }

  signOut(): string {
    return '/sign-out';
  }
}

export const Routes = new RouterBuilder();
