import React, {useEffect, useState} from "react";
import {Button, Typography} from 'antd';
import {PageSpin} from "../../components/PageSpin";
import {sendGet, sendPost} from "../../http/requester";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";

const {Title, Paragraph} = Typography;

export type Tenant = {
    id: number,
    name: string
}

export const TenantPage = () => {

    const [data, setData] = useState<Tenant | null>(null);
    const [apiKey, setApiKey] = useState<String>("**********");
    const [spin, setSpin] = useState(true);

    useEffect(() => {
        sendGet('tenants/current').then((res) => res.json()).then((r) => {
            setApiKey("**********");
            setData(r);
            setSpin(false);
        })
    }, []);

    return (<>
        <FullPageSpin visible={spin} />
        <Title>{data?.name}</Title>
        {apiKey.startsWith("***") ? (<p>API Key: {apiKey}</p>) :
            (<p>API Key: <Paragraph copyable>{apiKey}</Paragraph></p>)}

        <Button onClick={toggleVisibility(setSpin, async () => {
            const result = await sendPost('tenants/api-key').then(r => r.json());
            setApiKey(result.apiKey);
        })}>Re-generate ApiKey</Button>
    </>);
}
