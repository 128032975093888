import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import {sendPost} from "../../http/requester";
import newSchema from "./newSchema.json";

type CreateUserProps = {
    visible: boolean;
    hide: () => void;
};

export const CreateEvent = ({
                               visible,
                               hide,
                           }: CreateUserProps): ReactElement => {
    const [error, setError] = useState('');

    const onFinish = async (values: any) => {
        setError('');
        const response = await sendPost('events', {
            name: values.name,
            schema: values.schema,
        });
        if (response.ok || response.status < 500) {
            const result = await response.json();
            if (result.errorMessage) {
                setError(result.errorMessage);
            }
            else {
                return true;
            }
        } else {
            setError('Something bad happened. Please contact support.');
        }
        return false;
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    let errorMessage = <></>;
    if (error) {
        errorMessage = (
            <>
                <Row justify={'center'}>
                    <Col>
                        <Alert type={'error'} message={error} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
            </>
        );
    }

    return (
        <Modal
            open={visible}
            centered={true}
            footer={null}
            onCancel={() => hide()}
        >
            {errorMessage}
            <Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ name: '', schema: JSON.stringify(newSchema, null, 4) }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Name is required' }]}
                        >
                            <Input autoComplete={'off'} autoCorrect={'off'} />
                        </Form.Item>

                        <Form.Item
                            label="Schema"
                            name="schema"
                            rules={[
                                { required: true, message: 'Schema is required' },
                            ]}
                        >
                            <Input.TextArea rows={20} cols={50} autoComplete={'off'} autoCorrect={'off'} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};
