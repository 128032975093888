import * as React from 'react';
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import { DateTime } from 'luxon';
import {
    ApplicationUser,
    AuthContext,
    AuthContextState,
} from '../../auth/AuthContext';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {
    CopyOutlined,
    DeleteOutlined, EditOutlined,
    FireOutlined,
    GithubOutlined,
    GoogleOutlined,
    UserSwitchOutlined,
    WindowsOutlined
} from "@ant-design/icons";
import {CreateEvent} from "./CreateEvent";
import {Confirm, showConfirm} from "../../components/Confirm";

const { Title, Paragraph, Text } = Typography;

export const Events = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [spin, setSpin] = useState(false);
    const [events, setEvents] = useState<ApplicationUser[]>([]);
    const [createEventModal, setCreateEventModal] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const loadAsync = async () => {
        await sendGet(`events`)
            .then((res) => res.json())
            .then((d) => setEvents(d));
    };

    const load = () => {
        sendGet(`events`)
            .then((res) => res.json())
            .then((d) => setEvents(d));
    };


    useEffect(() => {
        load();
    }, []);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, row: any) => {
                return (<Paragraph copyable>{text}</Paragraph>)
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Created',
            key: 'created',
            render: (text: string, row: any) => {
                return (
                    <p key={'created' + row.id}>
                        {DateTime.fromISO(row.created).toFormat('d LLL yyyy, T')}
                    </p>
                );
            },
        },
        {
            title: 'Schema',
            dataIndex: 'schema',
            key: 'schema',
            render: (text: string, row: any) => {
                return (<Paragraph copyable>{text}</Paragraph>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, row: any) => (
                <Space key={'action' + row.id} size="middle">
                    <Button
                        type="primary"
                        disabled={true}
                        title={"Trigger"}>
                        <FireOutlined />
                    </Button>
                    <Button
                        type="primary"
                        title={"Delete"}
                        onClick={showConfirm(setConfirm, toggleVisibility(setSpin, async () => {
                            await sendDelete('events/' + row.id);
                            await loadAsync();
                        }))}>
                        <DeleteOutlined />
                    </Button>
                    <Button
                        type="primary"
                        title={"Duplicate"}
                        onClick={showConfirm(setConfirm, toggleVisibility(setSpin, async () => {
                            await sendPost('events/' + row.id + '/duplicate');
                            await loadAsync();
                        }))}>
                        <CopyOutlined />
                    </Button>
                    <Button
                        type="primary"
                        disabled={true}
                        title={"Edit"}>
                        <EditOutlined />
                    </Button>
                </Space>
            ),
        },
    ];

    const onCreate = () => {
        setCreateEventModal(true);
    };

    return (
        <Fragment>
            <CreateEvent
                visible={createEventModal}
                hide={() => {
                    load();
                    setCreateEventModal(false);
                }}
            />
            <Confirm visible={confirm}/>
            <FullPageSpin visible={spin} />
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Events
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={20}>
                    <Paragraph>
                        <blockquote>Add events and send them from your app.
                        </blockquote>
                    </Paragraph>
                </Col>
                <Col span={4} style={{textAlign: 'right'}}>
                    <Button type="primary" onClick={onCreate}>+ Add new event</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={'id'} dataSource={events} columns={columns}/>
                </Col>
            </Row>
        </Fragment>
    );
};