import Auth from "./requester";

export class AuthApiRouter {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  me(): string {
    const auth = Auth.getToken();
    return `${this.apiUrl}/${auth.tenant}/auth/me`;
  }

  signOut(): string {
    const auth = Auth.getToken();
    return `${this.apiUrl}/${auth.tenant}/auth/sign-out`;
  }

  login(tenant: string): string {
    return `${this.apiUrl}/${tenant}/auth/login`;
  }
}

export class ApiRouter {
  apiUrl: string;
  auth: AuthApiRouter;

  constructor(apiUrl: string, authApiUrl: string) {
    this.apiUrl = apiUrl;
    this.auth = new AuthApiRouter(authApiUrl);
  }

  url(url: string): string {
    const auth = Auth.getToken();
    return `${this.apiUrl}/${auth.tenant}/${url}`;
  }
}
