import { Routes } from './routeBuilder';

export default class Auth {
  static STORAGE_KEY = 'token';
  static TENANT_STORAGE_KEY = 'tenant';

  static getToken(): {token: string | null, tenant: string | null} {
    return {
      token: window.localStorage.getItem(Auth.STORAGE_KEY),
      tenant: window.localStorage.getItem(Auth.TENANT_STORAGE_KEY)
    };
  }

  static setToken(token: string, tenant: string): void {
    window.localStorage.setItem(Auth.STORAGE_KEY, token);
    window.localStorage.setItem(Auth.TENANT_STORAGE_KEY, tenant);
  }

  static removeToken(): void {
    window.localStorage.removeItem(Auth.STORAGE_KEY);
    window.localStorage.removeItem(Auth.TENANT_STORAGE_KEY);
  }
}

declare global {
  const chrome: any;
}

export const useQueryParams = () => {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop as string);
    },
  });
};

const getAuthHeaders = () => {
  const auth = Auth.getToken();
  return {
    Authorization: `Bearer ${auth.token}`,
    'Content-Type': 'application/json',
  };
};

export async function login(request: {Email: string, Password: string, Tenant: string}): Promise<any> {
  const resp = await fetch(Routes.api.auth.login(request.Tenant), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({Email: request.Email, Password: request.Password}),
    credentials: 'include',
  });

  if (resp.ok) {
    const result = await resp.json();
    const token = result.token;
    Auth.setToken(token, request.Tenant);
  }

  return resp.ok;
}

export async function signOut(): Promise<any> {
  const resp = await fetch(Routes.api.auth.signOut(), {
    method: 'POST',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  Auth.removeToken();

  return resp.ok;
}

export async function isAuthorized(): Promise<any> {
  const resp = await fetch(Routes.api.auth.me(), {
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp.ok;
}

export async function me(): Promise<any> {
  const resp = await fetch(Routes.api.auth.me(), {
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  if (resp.ok) {
    return await resp.json();
  }
  return null;
}

export async function sendPost(
  url: string,
  obj: any = null,
): Promise<Response> {
  if (null == obj) {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      credentials: 'include',
    });
  } else {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(obj),
      credentials: 'include',
    });
  }
}

export async function sendPostToAuth(
    url: string,
    obj: any = null,
    host: string | null = null
): Promise<Response> {
  const auth = Auth.getToken();
  const site = null != host ? `https://${host}` : `${Routes.authApiUrl}/${auth.tenant}`;
  const fullUrl =  `${site}/${url}`;
  if (null == obj) {
    return await fetch(fullUrl, {
      method: 'POST',
      headers: getAuthHeaders(),
      credentials: 'include',
    });
  } else {
    return await fetch(fullUrl, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(obj),
      credentials: 'include',
    });
  }
}

export async function sendPut(url: string, obj: any): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(obj),
    credentials: 'include',
  });

  return resp;
}

export async function sendDelete(url: string): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'DELETE',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}

export async function sendGet(url: string): Promise<any> {
  const resp = await fetch(Routes.api.url(url), {
    method: 'GET',
    headers: getAuthHeaders(),
    credentials: 'include',
  });

  return resp;
}
