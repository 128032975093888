import React, {useContext, useEffect} from 'react';
import './App.css';
import {MainLayout} from "./layout/MainLayout";
import {Route, Routes} from "react-router";
import {TenantPage} from "./pages/Tenant/TenantPage";
import {AuthContext, AuthContextState} from "./auth/AuthContext";
import {Login} from "./pages/login/Login";
import {SignOut} from "./pages/SignOut/SignOut";
import {FullPageSpin} from "./components/FullPageSpin";
import {Amplitude} from './analytics/Amplitude';
import {useLocation} from "react-router-dom";
import {clarity} from 'react-microsoft-clarity';
import {Users} from "./pages/Users/Users";
import {Accounts} from "./pages/Accounts/Accounts";
import {Authentication} from "./pages/Authentication/Authentication";
import {Authorization} from "./pages/Authorization/Authorization";
import {Integrations} from "./pages/Integrations/Integrations";
import {Automations} from "./pages/Automations/Automations";
import {Events} from "./pages/Events/Events";
import {CreateTenant} from "./pages/CreateTenant";

function App() {
    const authContext = useContext<AuthContextState>(AuthContext);

    let location = useLocation();

    useEffect(() => {
        Amplitude.getInstance().then(async (a) => {
            await a.track('page-load');
        })
    }, [location]);

    useEffect(() => {
        if (authContext.isAuthorized) {
            Amplitude.getInstance().then(async (a) => {
                a.setUser(authContext.user);
            })
        }
    }, [authContext.isAuthorized, authContext.user]);

    useEffect(() => {
        const key = process.env.REACT_APP_CLARITY_KEY;
        if (key) {
            clarity.init(key);
        }
    }, [])

    if (authContext.isLoadingAuthorization) {
        return (
            <FullPageSpin visible={authContext.isLoadingAuthorization}/>
        );
    }

    if (!authContext.isAuthorized) {
        return (
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/create-tenant" element={<CreateTenant/>}/>
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout><TenantPage/></MainLayout>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/users" element={<MainLayout><Users/></MainLayout>}/>
            <Route path="/authentication" element={<MainLayout><Authentication/></MainLayout>}/>
            <Route path="/authorization" element={<MainLayout><Authorization/></MainLayout>}/>
            <Route path="/events" element={<MainLayout><Events/></MainLayout>}/>
            <Route path="/automations" element={<MainLayout><Automations/></MainLayout>}/>
            <Route path="/integrations" element={<MainLayout><Integrations/></MainLayout>}/>
            <Route path="/accounts" element={<MainLayout><Accounts/></MainLayout>}/>
            <Route path="/sign-out" element={<SignOut/>}/>
        </Routes>
    );
}

export default App;
