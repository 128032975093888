import * as React from 'react';
import {Fragment, ReactElement, useContext, useEffect, useState} from 'react';
import {Button, Col, Descriptions, Form, Input, Row, Space, Switch, Table, Typography} from 'antd';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import type {CollapseProps} from 'antd';
import {Collapse} from 'antd';

type FieldType = {
    isEnabled?: boolean;
};

type SyncUsersWithBrevoFieldType = FieldType & {
    listId?: number;
};

export const Automations = (): ReactElement => {
    const [spin, setSpin] = useState(false);
    const [automations, setAutomations] = useState<any>([]);

    const load = async () => {
        return sendGet(`automations`)
            .then((res) => res.json())
            .then((d) => setAutomations(d));
    };

    useEffect(() => {
        load().then(e => e);
    }, []);

    const onSave = async (values: any, integration: any) => {
        let obj = {
            type: integration.type,
            id: integration.id,
            isEnabled: values.isEnabled,
            settings: values
        };
        delete obj.settings.isEnabled;
        sendPost(`automations`, obj).then(load);
    };


    return (
        <Fragment>
            <FullPageSpin visible={spin}/>
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Automations
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={['1']}>
                        {automations?.map((i: any, index: number) => {
                            if (i.type === "SyncUsersWithBrevo") {
                                return (<Collapse.Panel header={i.type} key={`${index + 1}`}>
                                    <Form
                                        name="basic"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        style={{maxWidth: 600}}
                                        initialValues={{isEnabled: i.isEnabled, listId: i.settings.listId}}
                                        disabled={spin}
                                        onFinish={toggleVisibility(setSpin, async (values) => {
                                            await onSave(values, i)
                                        })}
                                        autoComplete="off"
                                    >
                                        <Form.Item<SyncUsersWithBrevoFieldType>
                                            label="Is Enabled"
                                            name="isEnabled"
                                            valuePropName={'checked'}
                                        >
                                            <Switch/>
                                        </Form.Item>

                                        <Form.Item<SyncUsersWithBrevoFieldType>
                                            label="List Id"
                                            name="listId"
                                        >
                                            <Input/>
                                        </Form.Item>


                                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Collapse.Panel>)
                            } else {
                                return (<Collapse.Panel header={i.type} key={`${index + 1}`}>
                                    <Form
                                        name="basic"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        style={{maxWidth: 600}}
                                        initialValues={{isEnabled: i.isEnabled}}
                                        disabled={spin}
                                        onFinish={toggleVisibility(setSpin, async (values) => {
                                            await onSave(values, i)
                                        })}
                                        autoComplete="off"
                                    >
                                        <Form.Item<FieldType>
                                            label="Is Enabled"
                                            name="isEnabled"
                                            valuePropName={'checked'}
                                        >
                                            <Switch/>
                                        </Form.Item>


                                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Collapse.Panel>)
                            }
                        })}
                    </Collapse>
                </Col>
            </Row>
        </Fragment>
    );
};