import * as React from "react";
import {Fragment, ReactElement, useContext, useEffect, useState} from "react";
import {ApplicationUser, AuthContext, AuthContextState} from "../../auth/AuthContext";
import {sendGet, sendPostToAuth} from "../../http/requester";
import {DateTime} from "luxon";
import {Button, Col, Row, Space, Table, Typography} from "antd";
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {UserSwitchOutlined} from "@ant-design/icons";

export const Authorization = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [spin, setSpin] = useState(false);
    const [roles, setRoles] = useState<ApplicationUser[]>([]);

    const load = () => {
        sendGet(`authorization/roles`)
            .then((res) => res.json())
            .then((d) => setRoles(d));
    };


    useEffect(() => {
        load();
    }, []);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }
    ];

    return (
        <Fragment>
            <FullPageSpin visible={spin}/>
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Roles
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={'id'} dataSource={roles} columns={columns}/>
                </Col>
            </Row>
        </Fragment>
    );
};