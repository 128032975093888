import * as React from 'react';
import {Fragment, ReactElement, useContext, useEffect, useState} from 'react';
import {Button, Col, Descriptions, Form, Input, Row, Space, Table, Typography} from 'antd';
import {sendDelete, sendGet, sendPost, sendPostToAuth} from '../../http/requester';
import {FullPageSpin, toggleVisibility} from "../../components/FullPageSpin";
import {Collapse} from 'antd';

type FieldType = {
    apiKey?: string;
};

export const Integrations = (): ReactElement => {
    const [spin, setSpin] = useState(false);
    const [integrations, setIntegrations] = useState<any>([]);

    const load = async () => {
        return sendGet(`integrations`)
            .then((res) => res.json())
            .then((d) => setIntegrations(d));
    };

    useEffect(() => {
        load().then(e => e);
    }, []);

    const onSave = async (values: any, integration: any) => {
        sendPost(`integrations`, {
            type: integration.type,
            id: integration.id,
            apiKey: values.apiKey
        }).then(load);
    };

    const renderGeneric = (i: any, index: number, apiKeyName: string) => {
        return <Collapse.Panel header={i.type} key={`${index + 1}`}>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ apiKey: i.apiKey }}
                disabled={spin}
                onFinish={toggleVisibility(setSpin, async (values) => {
                    await onSave(values, i)
                })}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label={apiKeyName}
                    name="apiKey"
                    rules={[{ required: true, message: `Please enter ${apiKeyName}` }]}
                >
                    <Input />
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Collapse.Panel>
    };

    const renderIntegration = (integration: any, index: number) => {
        if (integration.type === 'Telegram') {
            return renderGeneric(integration, index, 'Bot Token');
        }
        return renderGeneric(integration, index, 'Api Key');
    };

    return (
        <Fragment>
            <FullPageSpin visible={spin}/>
            <Row>
                <Col>
                    <Typography.Title level={1} style={{margin: 0}}>
                        Integrations
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={['1']}>
                        {integrations?.map(renderIntegration)}
                    </Collapse>
                </Col>
            </Row>
        </Fragment>
    );
};
